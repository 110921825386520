import React, { useState } from "react"
import {
  Box,
  Input,
  Button,
  Test,
  Text,
  FormControl,
  FormLabel,
  Select,
  Flex,
  Textarea,
} from "@chakra-ui/core"
import MultiSelect from "react-select/creatable"
import { useForm } from "react-hook-form"

const TestFeature = () => {
  //React Hook Form
  const { register, handleSubmit } = useForm()
  const [value, setValue] = React.useState("")
  const [value2, setValue2] = React.useState("")
  const [value3, setValue3] = useState("")
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedIndex, setSelectedIndex] = useState()
  //Fields
  const [fields, setFields] = React.useState([])
  const [fields2, setFields2] = React.useState([])

  //Multi select options

  const options = [
    { value: "firstName", label: "First Name" },
    { value: "lastName", label: "Last Name" },
    { value: "address", label: "Address" },
    { value: "gender", label: "Gender" },
    { value: "phoneNumber", label: "Phone Number" },
    { value: "others", label: "Others" },
  ]

  //handle change
  const handleInputSelect = (event) => {
    setSelectedOptions(event)
  }

  //Toggle Functions
  const [isToggled, setIsToggled] = useState(false)
  const [isToggled2, setIsToggled2] = useState(false)
  const [isToggled3, setIsToggled3] = useState(false)

  const handleChange = (data) => {
    // setValue(event.target.value)

    //Add the update field to the array
    // let newArray = [...selectedOptions]
    // console.log(selectedIndex)
    // console.log(newArray[selectedIndex])
    // newArray[selectedIndex].value = event.target.value
    // setSelectedOptions(newArray)

    console.log(data)
  }

  const addNewField = () => {
    setFields([...fields, value])
    setIsToggled(!isToggled)
  }

  const addNewField2 = () => {
    setFields2([...fields2, value2])
    setIsToggled2(!isToggled2)
  }

  //Render different inputs
  const renderInput = (option) => {
    switch (option) {
      case "text":
        // case 1
        return (
          <FormControl w="75%" mt="2">
            <FormLabel>Enter Text</FormLabel>
            <Input type="text" my="0" placeholder="Start typing" />
          </FormControl>
        )
        break
      case "image":
        // Case 2
        return <Button>Uplode Image</Button>
        break
      case "date":
        // Case 2
        return <Input type="date" placeholder="Select Date" />
        break
      default:
        return <Text></Text>
    }
  }

  //Render different inputs
  const renderInput2 = (option) => {
    switch (option) {
      case "firstName":
        // case 1
        return (
          <FormControl w="75%" mt="2">
            <FormLabel>Enter First Name</FormLabel>
            <Input type="text" my="0" placeholder="Start typing" />
          </FormControl>
        )
        break
      case "lastName":
        // Case 2
        return (
          <FormControl w="75%" mt="2">
            <FormLabel>Last Name</FormLabel>
            <Input type="text" my="0" placeholder="Start typing" />
          </FormControl>
        )
        break
      case "phoneNumber":
        // Case 2
        return (
          <FormControl w="75%" mt="2">
            <FormLabel>Phone Number</FormLabel>
            <Input type="text" my="0" placeholder="Start typing" />
          </FormControl>
        )
        break
      case "houseAddress":
        // Case 2
        return (
          <FormControl w="95%" mt="2">
            <FormLabel>House Address</FormLabel>
            <Textarea my="0" placeholder="Start typing" />
          </FormControl>
        )
        break
      default:
        return <Text></Text>
    }
  }
  console.log(selectedIndex)
  return (
    <Box p="6">
      {/* First Option */}
      <Text fontSize="28px" fontWeight="bold" mb="2">
        First Option
      </Text>
      {fields.map((field, index) => (
        <FormControl key={index} w="25%" mt="2">
          <FormLabel>{field}</FormLabel>
          <Input type="text" my="0" placeholder="Start typing" />
        </FormControl>
      ))}

      {!isToggled && (
        <Button onClick={() => setIsToggled(!isToggled)} my="3">
          Add New Field
        </Button>
      )}

      {/* Enter Field Title */}
      {isToggled && (
        <FormControl w="85%" mt="2">
          <Input
            pl="1"
            py="0"
            border="0px"
            defaultValue="Store Owners"
            w="25%"
            fontWeight="bold"
          />
          <Input w="25%" onChange={handleChange} value={value} mr="3" />
          <Button onClick={addNewField} my="4">
            Enter
          </Button>
        </FormControl>
      )}

      {/* Second Option */}
      <Text fontSize="28px" fontWeight="bold" mt="8" mb="2">
        Second Option
      </Text>
      <Box>
        {isToggled2 && (
          <div>
            <Text>Select field title</Text>
            <Flex>
              <Select
                placeholder="Select option"
                w="25%"
                mr="3"
                onChange={(event) => setValue2(event.target.value)}
              >
                <option value="firstName">First name</option>
                <option value="lastName">Last name</option>
                <option value="phoneNumber">Phone Number</option>
                <option value="houseAddress">House Address</option>
                <option value="option3">Other</option>
              </Select>
            </Flex>

            <Box my="3" w="30%">
              {renderInput2(value2)}
            </Box>
          </div>
        )}

        {/* Render Selected option */}
        {fields2.map((field, index) => (
          <FormControl key={index} w="25%" mt="2">
            <FormLabel>{field}</FormLabel>
            <Input type="text" my="0" placeholder="Start typing" />
          </FormControl>
        ))}

        {!isToggled2 && (
          <Button mt="1" onClick={() => setIsToggled2(!isToggled2)}>
            Add New Field from Drop Down
          </Button>
        )}

        {/* Third Option - Field Type */}
        <Text fontSize="28px" fontWeight="bold" mt="8" mb="2">
          Third Option
        </Text>
        <Box>
          <Button onClick={() => setIsToggled3(!isToggled3)} mb="4">
            Select type of field
          </Button>

          {/* Field Type */}
          {isToggled3 && (
            <div>
              <Text>Select Field Type</Text>
              <Flex>
                <Select
                  placeholder="Select an option"
                  w="25%"
                  mr="3"
                  onChange={(event) => setValue3(event.target.value)}
                  value={value3}
                >
                  <option value="text">Text</option>
                  <option value="image">Image</option>
                  <option value="date">Date</option>
                </Select>
              </Flex>

              {/* Render Input based on option */}
              <br />
              <Box my="3" w="30%">
                {renderInput(value3)}
              </Box>
            </div>
          )}
        </Box>

        {/* Fourth Option - Multi Select  */}
        <Text fontSize="28px" fontWeight="bold" mt="8" mb="2">
          Fourth Option
        </Text>

        {/*  Multi Select  */}

        <Box w="35%">
          <MultiSelect
            placeholder="Select or type a custom option"
            onChange={handleInputSelect}
            options={options}
            isMulti
            name="selectedOptions"
            isClearable={false}
          />
        </Box>
      </Box>

      <Box as="form" onSubmit={handleSubmit(handleChange)}>
        {selectedOptions &&
          selectedOptions.map((option, index) => (
            <FormControl key={index} isRequired>
              {console.log(index)}
              <Input
                pl="1"
                py="0"
                border="0px"
                defaultValue={option.label}
                ref={register}
                name={option.value + "Title"}
                w="25%"
                fontWeight="bold"
              />
              <Input w="25%" ref={register} name={option.value} mr="3" />
            </FormControl>
          ))}
        <Button type="submit" my="3">
          Done
        </Button>
      </Box>
    </Box>
  )
}

export default TestFeature
