import React from "react"
import { Box, Text, Button } from "@chakra-ui/core"

class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
  }
  render() {
    if (this.state.hasError) {
      return (
        <Box
          m="auto"
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          h="100vh"
        >
          <Text>We're sorry — something's gone wrong.</Text>
          <Text>Our team has been notified.</Text>
          <Button
            as="a"
            variant="outline"
            variantColor="gray.700"
            py="5"
            my="5"
            mx="auto"
            w={["50%", "20%", "20%", "10%"]}
            _hover={{ bg: "#ebedf0" }}
            onClick={() => window.location.reload()}
          >
            Reload App
          </Button>
        </Box>
      )
    }
    return this.props.children
  }
}
export default ErrorBoundary
