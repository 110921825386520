import React from "react"
import { useHistory } from "react-router-dom"
import { Box, Text, Button, Heading } from "@chakra-ui/core"

const Page404 = () => {
  let history = useHistory()
  return (
    <>
      <Box
        m="auto"
        textAlign="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        h="100vh"
      >
        <Heading>Oops,</Heading>
        <Text>We can't find the page you are looking for</Text>
        <Button
          onClick={() => history.goBack()}
          my="5"
          variant="outline"
          width="10%"
          mx="auto"
          variantColor="gray.700"
          _hover={{ bg: "#ebedf0" }}
        >
          Take me back
        </Button>
      </Box>
    </>
  )
}

export default Page404
