import React, { useState } from "react"

// Libraries
import { Link as RouterLink, useHistory, Redirect } from "react-router-dom"
import Cookies from "js-cookie"

//graphql
import { useMutation } from "@apollo/react-hooks"
import { ADMIN_LOGIN } from "../../graphql/Mutations.js"

// Assets
import logo from "../../assets/logo.svg"
import logo2 from "../../assets/identitoBlueLogo.svg"
import man from "../../assets/Auth/man.png"

//library
import {
  useToast,
  Box,
  Image,
  Text,
  Button,
  Link,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/core"

//Auth
import { Form, Formik, Field } from "formik"
import * as Yup from "yup"

const ResetPassword = () => {
  const history = useHistory()
  let toast = useToast()

  //Local state
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  //============== login query=====
  const [adminLogin, { loading }] = useMutation(ADMIN_LOGIN, {
    onCompleted({ admin_login }) {
      const { value, message, admin, token } = admin_login

      if (value) {
        Cookies.set("tok-auth", token, { expires: 7 })
        localStorage.setItem("userData", JSON.stringify(admin))
        history.push("/")

        toast({
          description: message,
          duration: 9000,
          isClosable: true,
          status: `success`,
          position: "bottom-right",
        })
      } else {
        toast({
          description: message,
          duration: 9000,
          isClosable: true,
          status: `error`,
          position: "bottom-right",
        })
      }
    },
    onError(err) {
      console.log(err)
      return null
    },
  })

  return (
    <Flex flexDir={["column", "column", "column", "row"]} w="100%" h="100vh">
      <Flex
        minH="150px"
        background={`linear-gradient(#00000066,#00000066),url(${man}) no-repeat`}
        backgroundSize="cover"
        backgroundPosition="center"
        w={["100%", "100%", "100%", "50%"]}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          backgroundColor="rgba(31, 129, 200, 0.75)"
          w="100%"
          h="100%"
          opacity=".8"
        ></Box>
      </Flex>

      <Box
        w={["100%", "100%", "100%", "50%"]}
        pl={["10", "24", "48", "24"]}
        pr={["10", "24", "48", "48"]}
        pt={["6", "6", "16"]}
        h="100vh"
      >
        <Image src={logo2} mb="16" />
        {/* TABS */}
        <Text as="h1" fontSize="30px" mt="5" fontWeight="bold">
          Reset Password
        </Text>
        <Text fontWeight="600" fontSize="sm" mb="5">
          Please enter a new password
        </Text>
        {/* Form */}
        <Flex flexDir="column">
          <Formik
            initialValues={{
              password: "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required("Password is required"),
            })}
            onSubmit={(fields) => {
              try {
                adminLogin({
                  variables: {
                    password: fields.password,
                  },
                })
              } catch (error) {
                return null
              }
            }}
          >
            {(props) => (
              <Form>
                {/* Password */}
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      mb="3"
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel fontSize="14px" htmlFor="password">
                        Password
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          {...field}
                          placeholder="***********"
                          fontSize="sm"
                          type={show ? "text" : "password"}
                        />
                        {/* Show/hide password */}
                        <InputRightElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={handleClick}>
                            {show ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>

                      <FormErrorMessage fontSize="xs">
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                {/* Reset Password Button */}
                <Button
                  w="100%"
                  as="button"
                  type="success"
                  backgroundColor="#303030"
                  py="6"
                  fontSize="18px"
                  color="white"
                  _hover={{ bg: "gray.600" }}
                  isLoading={loading}
                  mb="3"
                >
                  Submit
                </Button>

                <br />

                {/* Login Link */}
                <Link
                  to="/login"
                  as={RouterLink}
                  fontSize="14px"
                  fontWeight="100"
                  mr="2"
                >
                  Didn't request for this?
                  <Text as="span" fontWeight="600" ml="1">
                    Please login
                  </Text>
                </Link>
              </Form>
            )}
          </Formik>
        </Flex>
      </Box>
    </Flex>
  )
}

export default ResetPassword
