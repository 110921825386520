import React from "react"

import {
  Box,
  Image,
  Heading,
  Text,
  Button,
  Link as ChakraLink,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  FormHelperText,
  SimpleGrid,
  useToast,
} from "@chakra-ui/core"

//Apollo
import { useMutation } from "@apollo/react-hooks"

//Formik
import { Form, Formik, Field } from "formik"
import * as Yup from "yup"
import { useHistory } from "react-router-dom"

//local import
import { ADMIN_FORGOT_PASSWORD_REQUEST } from "../../graphql/Mutations"

const ForgotPassword = (params) => {
  let toast = useToast()
  let history = useHistory()

  //Mutation
  const [
    adminForgotPasswordRequest,
    { loading: passwordLoading },
  ] = useMutation(ADMIN_FORGOT_PASSWORD_REQUEST, {
    onCompleted({ admin_forgot_password_request }) {
      const { value, message } = admin_forgot_password_request
      if (value) {
        toast({
          description: message,
          isClosable: true,
          position: "bottom-right",
          status: "success",
          duration: 9000,
        })
        history.push("/login")
      } else {
        toast({
          description: message,
          status: "error",
          isClosable: true,
          position: "bottom-right",
          duration: 9000,
        })
      }
    },
    onError(err) {
      console.log(err)
      return null
    },
  })
  return (
    <Flex w="100%" h="100vh" justify="center" align="center">
      <Box px="10" py="16" border="1px solid #DADADA" w="38%" h="50%">
        <Flex justify="space-between" mb="6">
          <Heading as="h6" fontSize="22px">
            Forgot Your Password?
          </Heading>
          <ChakraLink color="#3492D0" href="/login">
            Back to Sign In
          </ChakraLink>
        </Flex>

        <Text mb="6">We’ll send you a link so you can reset it</Text>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Email is invalid")
              .required("Email is required"),
          })}
          onSubmit={(fields) => {
            try {
              adminForgotPasswordRequest({
                variables: {
                  ...fields,
                },
              })
            } catch (error) {
              return null
            }
          }}
        >
          <Form>
            <Field name="email">
              {({ field, form }) => (
                /* Email Form */

                <FormControl
                  isRequired
                  mb="8"
                  isInvalid={form.errors.email && form.touched.email}
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    placeholder="jessica.chris@identito.com"
                    name="email"
                    {...field}
                  />
                  <FormErrorMessage fontSize="xs">
                    {form.errors.email}
                  </FormErrorMessage>
                  {/* Button */}
                  <Button
                    mt="3"
                    backgroundColor="#303030"
                    color="white"
                    w="100%"
                    py="23px"
                    isLoading={passwordLoading}
                    type="submit"
                  >
                    Send me a link
                  </Button>
                </FormControl>
              )}
            </Field>
          </Form>
        </Formik>
      </Box>
    </Flex>
  )
}

export default ForgotPassword

// onClick={() => {
//                     updateRecord({
//                       variables: {
//                         record_id: record._id,
//                         record_status: "unverified",
//                       },
//                     })
//                   }}
