import React from "react"
// Assets
import logo from "../../assets/logo.svg"
import logo2 from "../../assets/identitoBlueLogo.svg"
import man from "../../assets/Auth/man.png"
// Libraries
import {
  Box,
  Image,
  Text,
  Button,
  Link as ChakraLink,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/core"

// Libraries
import { Link as RouterLink, useHistory } from "react-router-dom"
import Cookies from "js-cookie"
import { Form, Formik, Field } from "formik"
import * as Yup from "yup"

//graphql
import { useMutation } from "@apollo/react-hooks"
import { CREATE_SUPER_ADMIN } from "../../graphql/Mutations.js"

const Register = () => {
  const history = useHistory()
  let toast = useToast()
  //Register Mutation
  const [createSuperAdmin, { loading }] = useMutation(CREATE_SUPER_ADMIN, {
    onCompleted({ create_super_admin }) {
      const { value, message, admin, token } = create_super_admin

      if (value) {
        Cookies.set("tok-auth", token, { expires: 7 })
        localStorage.setItem("userData", JSON.stringify(admin))
        history.push("/")

        toast({
          description: message,
          duration: 9000,
          isClosable: true,
          status: `success`,
          position: "bottom-right",
        })
      } else {
        toast({
          description: message,
          duration: 9000,
          isClosable: true,
          status: `error`,
          position: "bottom-right",
        })
      }
    },
    onError(err) {
      console.log(err)
      return null
    },
  })
  return (
    <Flex flexDir={["column", "column", "column", "row"]} w="100%" h="100vh">
      <Flex
        minH="150px"
        background={`linear-gradient(#00000066,#00000066),url(${man}) no-repeat`}
        backgroundSize="cover"
        backgroundPosition="center"
        w={["100%", "100%", "100%", "50%"]}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          backgroundColor="rgba(31, 129, 200, 0.75)"
          w="100%"
          h="100%"
          opacity=".8"
        ></Box>
      </Flex>

      <Box
        w={["100%", "100%", "100%", "50%"]}
        pl={["10", "24", "48", "24"]}
        pr={["10", "24", "48", "48"]}
        pt={["6", "6", "12"]}
        h="100vh"
      >
        <Image src={logo2} mb="12" />
        {/* TABS */}
        <Text as="h1" fontSize="30px" mt="5" fontWeight="bold" mb="6">
          Admin Register
        </Text>
        <Formik
          initialValues={{
            email: "",
            password: "",
            phone_number: "",
            full_name: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Email is invalid")
              .required("Email is required"),

            password: Yup.string().required("Password is required"),
            phone_number: Yup.string().required("Phone number is required"),
            full_name: Yup.string().required("Full name is required"),
          })}
          onSubmit={(fields) => {
            try {
              //convert number to string
              let phoneNumber = fields.phone_number.toString()
              createSuperAdmin({
                variables: {
                  full_name: fields.full_name,
                  email: fields.email,
                  phone_number: phoneNumber,
                  password: fields.password,
                },
              })
            } catch (error) {
              return null
            }
          }}
        >
          {(props) => (
            <Form>
              <Flex flexDir="column">
                {/* Full Name */}
                <Field name="full_name">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      mb="6"
                      isInvalid={
                        form.errors.full_name && form.touched.full_name
                      }
                    >
                      <FormLabel htmlFor="full_name" fontSize="14px">
                        Full Name
                      </FormLabel>
                      <Input
                        {...field}
                        id="full_name"
                        placeholder="Muhammad Salihu"
                      />
                      <FormErrorMessage fontSize="xs">
                        {form.errors.full_name && form.touched.full_name}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {/* Email */}
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      mb="6"
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel htmlFor="email" fontSize="14px">
                        Email
                      </FormLabel>
                      <Input
                        {...field}
                        id="email"
                        placeholder="m.salihu@identito.com"
                      />

                      <FormErrorMessage fontSize="xs">
                        {form.errors.email}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {/* Phone Number */}
                <Field name="phone_number">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      mb="6"
                      isInvalid={
                        form.errors.phone_number && form.touched.phone_number
                      }
                    >
                      <FormLabel htmlFor="phone_number" fontSize="14px">
                        Phone Number
                      </FormLabel>
                      <Input
                        {...field}
                        type="number"
                        placeholder="+23480364732"
                      />

                      <FormErrorMessage fontSize="xs">
                        {form.errors.phone_number}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {/* Password */}
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      mb="6"
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel htmlFor="password" fontSize="14px">
                        Password
                      </FormLabel>
                      <Input
                        {...field}
                        id="password"
                        placeholder="*******"
                        type="password"
                      />
                      <FormErrorMessage fontSize="xs">
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {/* Register Button */}
                <Button
                  backgroundColor="#303030"
                  color="white"
                  mb="1"
                  fontSize="18px"
                  py="6"
                  type="submit"
                  isLoading={loading}
                >
                  Register
                </Button>

                {/* Sign Up Link */}
                <Flex fontSize="14px">
                  <Text mr="2" fontWeight="100">
                    Already have an account?,
                  </Text>
                  <ChakraLink fontWeight="600" href="/login">
                    Login
                  </ChakraLink>
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  )
}

export default Register
