import React, { useRef, lazy, Suspense } from "react"
import { Switch, Route, Redirect, useLocation } from "react-router-dom"
//component
import Sidebar from "../components/Sidebar"
import SidebarContent from "../components/Sidebar/SidebarContent"
import Loader from "../components/Loader"

//library
import {
  Box,
  useDisclosure,
  DrawerContent,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerBody,
  IconButton,
  Flex,
  Image,
} from "@chakra-ui/core"

//Contexts
import ClientContext from "../context/ClientContext"
//assets
import logo from "../assets/Logo/logoBlue.svg"
import Cookies from "js-cookie"

const Analytics = lazy(() => import("./Analytics"))
const Admins = lazy(() => import("./Admins"))
const Clients = lazy(() => import("./Clients"))
const Agents = lazy(() => import("./Agents"))
const Batches = lazy(() => import("./Batches"))
const Records = lazy(() => import("./Records"))
const UpdateProfile = lazy(() => import("./UpdateProfile/index.js"))

//checks for authentication
const isAuth = () => {
  const token = Cookies.get("tok-auth")
  if (token) {
    return true
  }

  if (token === undefined) {
    return false
  }
  return false
}

const Layout = () => {
  let location = useLocation()
  //this right here handles the sidedrawer on mobile screens
  const { isOpen, onClose, onOpen } = useDisclosure()
  const btnRef = useRef()

  let sideDrawer = (
    <Drawer
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent bg="blaq" mx="0">
        <DrawerCloseButton color="#f7f7f7" />
        <DrawerBody px="0" className="drawer">
          <Flex direction="column" mt="5">
            <SidebarContent />
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )

  if (isAuth()) {
    return (
      <>
        <Sidebar />
        <Box className="mainContainer" bg="#F4F4F4;">
          <Box
            ref={btnRef}
            onClick={onOpen}
            px="2"
            py="2"
            justifyContent="space-between"
            display={["flex", "flex", "flex", "none"]}
            bg="blaq"
          >
            <IconButton
              fontSize="lg"
              bg="blaq"
              color="white"
              aria-label="Toggle sidebar"
              icon="menu"
            />
            <Image src={logo} width="30px" mx="2" />
          </Box>

          <Box>
            {/*
             <Box
              borderTop="4px"
              borderColor="#1F263E"
              display={["none", "none", "none", "block"]}
            ></Box>
            */}

            <Switch>
              <Suspense fallback={<Loader />}>
                <Route exact path="/" component={Analytics} />

                <ClientContext>
                  <Route path="/admins" component={Admins} />
                  <Route path="/clients" component={Clients} />
                  <Route path="/agents" component={Agents} />
                  <Route path="/batches" component={Batches} />
                </ClientContext>

                <Route path="/records" component={Records} />
                <Route path="/update-profile" component={UpdateProfile} />
              </Suspense>
            </Switch>
          </Box>
        </Box>
      </>
    )
  }
  return <Redirect to={{ pathname: "/login", state: { from: location } }} />
}
export default Layout
