import React from "react"
import ReactDOM from "react-dom"
import App from "./App"

// react-router
// import { BrowserRouter } from "react-router-dom"

// chakra
import { ThemeProvider, CSSReset } from "@chakra-ui/core"
import { CustomTheme } from "./Helpers/Theme"

//GraphQL
import { ApolloProvider } from "@apollo/react-hooks"
import client from "./graphql/client"

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={CustomTheme}>
      <CSSReset />
      <App />
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root")
)
