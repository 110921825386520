import gql from "graphql-tag"

const CREATE_SUPER_ADMIN = gql`
  mutation createSuperAdmin(
    $full_name: String!
    $email: String!
    $phone_number: String!
    $password: String!
  ) {
    create_super_admin(
      full_name: $full_name
      email: $email
      phone_number: $phone_number
      password: $password
    ) {
      value
      message
      token
      admin {
        _id
        full_name
        email
        phone_number
      }
    }
  }
`

const CREATE_ADMIN = gql`
  mutation createAdmin(
    $full_name: String!
    $email: String!
    $phone_number: String!
  ) {
    create_another_admin(
      full_name: $full_name
      email: $email
      phone_number: $phone_number
    ) {
      value
      message
    }
  }
`
const UPDATE_ADMIN_PASSWORD = gql`
  mutation adminPasswordUpdate($old_password: String!, $new_password: String!) {
    admin_password_update(
      old_password: $old_password
      new_password: $new_password
    ) {
      value
      message
    }
  }
`

const CREATE_AGENT = gql`
  mutation createAgent(
    $full_name: String!
    $email: String!
    $phone_number: String!
  ) {
    admin_create_agent(
      full_name: $full_name
      email: $email
      phone_number: $phone_number
    ) {
      value
      message
    }
  }
`
const CREATE_CLIENT = gql`
  mutation createClient(
    $full_name: String!
    $email: String!
    $phone_number: String!
    $client_type: String!
    $client_description: String!
  ) {
    admin_create_client(
      full_name: $full_name
      email: $email
      phone_number: $phone_number
      client_type: $client_type
      client_description: $client_description
    ) {
      value
      message
    }
  }
`
const CREATE_BATCH = gql`
  mutation createBatch(
    $batch_name: String!
    $batch_description: String!
    $client_owner: ID!
    $assign_agents: Boolean!
    $records_input_fields: String
    $has_custom_fields: Boolean
    $agents: [ID!]
  ) {
    admin_create_batch(
      batch_name: $batch_name
      batch_description: $batch_description
      client_owner: $client_owner
      assign_agents: $assign_agents
      agents: $agents
      has_custom_fields: $has_custom_fields
      records_input_fields: $records_input_fields
    ) {
      value
      message
    }
  }
`

const ADMIN_LOGIN = gql`
  mutation adminLogin($email: String!, $password: String!) {
    admin_login(email: $email, password: $password) {
      value
      message
      token
      admin {
        _id
        full_name
        email
        phone_number
      }
    }
  }
`

const ASSIGN_AGENTS_TO_BATCH = gql`
  mutation assignAgentsToBatch($batch_id: ID!, $agents: [ID!]) {
    assign_agents_to_batch(batch_id: $batch_id, agents: $agents) {
      value
      message
    }
  }
`
const ASSIGN_ADMINS_TO_CLIENT = gql`
  mutation assignAdminsToClient($client_id: ID!, $admins: [ID!]) {
    assign_admins_to_client(client_id: $client_id, admins: $admins) {
      value
      message
    }
  }
`

const CREATE_RECORD_MANUALLY = gql`
  mutation createRecordManually(
    $first_name: String
    $last_name: String
    $phone_number: String
    $office_address: String
    $house_address: String
    $gender: String
    $verification_batch: ID!
  ) {
    add_record_manually(
      first_name: $first_name
      last_name: $last_name
      phone_number: $phone_number
      office_address: $office_address
      house_address: $house_address
      gender: $gender
      verification_batch: $verification_batch
    ) {
      value
      message
    }
  }
`
const CREATE_CUSTOM_RECORD = gql`
  mutation add_custom_record(
    $custom_record_fields: String!
    $verification_batch: ID!
  ) {
    add_custom_record(
      custom_record_fields: $custom_record_fields
      verification_batch: $verification_batch
    ) {
      value
      message
    }
  }
`

const MULTIPLE_RECORDS_CSV = gql`
  mutation add_multiple_records_csv(
    $verification_batch: ID!
    $csv_records: [RECORDS_CSV_INPUT]
  ) {
    add_multiple_records_csv(
      verification_batch: $verification_batch
      csv_records: $csv_records
    ) {
      message
      value
    }
  }
`

const DELETE_AGENT = gql`
  mutation($agent_id: ID!) {
    delete_single_agent(agent_id: $agent_id) {
      message
      value
    }
  }
`
const DELETE_ADMIN = gql`
  mutation($admin_id: ID!) {
    delete_single_admin(admin_id: $admin_id) {
      message
      value
    }
  }
`
const DELETE_RECORD = gql`
  mutation($record_id: ID!) {
    delete_single_record(record_id: $record_id) {
      message
      value
    }
  }
`
const DELETE_CLIENT = gql`
  mutation($client_id: ID!) {
    delete_client(client_id: $client_id) {
      message
      value
    }
  }
`
const UPDATE_AGENT_STATUS = gql`
  mutation($agent_id: ID!, $status: String!) {
    update_agent_status(agent_id: $agent_id, status: $status) {
      message
      value
    }
  }
`
const DELETE_ADMIN_BATCH = gql`
  mutation($batch_id: ID!) {
    delete_admin_batch(batch_id: $batch_id) {
      message
      value
    }
  }
`

const UPATE_RECORD = gql`
  mutation updateRecord($record_status: String!, $record_id: ID!) {
    update_record(record_id: $record_id, record_status: $record_status) {
      message
      value
    }
  }
`
const ADMIN_FORGOT_PASSWORD_REQUEST = gql`
  mutation adminForgotPasswordRequest($email: String!) {
    admin_forgot_password_request(email: $email) {
      message
      value
    }
  }
`
const ADMIN_UPDATE_PROFILE = gql`
  mutation adminUpdateProfile($email: String, $phone_number: String) {
    admin_update_profile(email: $email, phone_number: $phone_number) {
      message
      value
    }
  }
`

export {
  MULTIPLE_RECORDS_CSV,
  ADMIN_LOGIN,
  UPDATE_ADMIN_PASSWORD,
  CREATE_SUPER_ADMIN,
  ADMIN_UPDATE_PROFILE,
  CREATE_ADMIN,
  CREATE_AGENT,
  CREATE_CLIENT,
  CREATE_BATCH,
  CREATE_RECORD_MANUALLY,
  CREATE_CUSTOM_RECORD,
  ASSIGN_AGENTS_TO_BATCH,
  ASSIGN_ADMINS_TO_CLIENT,
  DELETE_AGENT,
  DELETE_ADMIN,
  DELETE_CLIENT,
  UPATE_RECORD,
  DELETE_RECORD,
  DELETE_ADMIN_BATCH,
  ADMIN_FORGOT_PASSWORD_REQUEST,
  UPDATE_AGENT_STATUS,
}
