import React from "react"
//libraries
import { Box, Flex, Image } from "@chakra-ui/core"
import logo from "../../assets/Logo/logoBlue.svg"
import logo2 from "../../assets/identitoBlueLogo.svg"

import SidebarContent from "./SidebarContent"

const Sidebar = () => {
  return (
    <>
      <Box
        flexDirection="column"
        backgroundColor="#1F263E"
        display={["none", "none", "none", "block"]}
        id="side"
        pt="5"
        borderTop="4px"
        overflow="hidden"
      >
        <Flex px="8">
          <Image
            w="118px"
            h="35px"
            src={logo2}
            objectFit="cover"
            alt="Identito Logo"
          />
        </Flex>
        <SidebarContent />
      </Box>
    </>
  )
}
export default Sidebar
