import React from "react"
import { Box, Flex, Image, Spinner, Heading, Text } from "@chakra-ui/core"
import logo from "../assets/logo.svg"
import logo2 from "../assets/identitoBlueLogo.svg"

const Loader = () => {
  return (
    <Box
      textAlign="center"
      my="auto"
      mx="auto"
      height="100%"
      py={["25%", "25%", "25%", "15%"]}
    >
      <Flex textAlign="center" mx={["25%", "25%", "35%", "41.5%"]} mb="12">
        <Image src={logo2} alt="cbtPal" w="100%" />
      </Flex>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
      <Text mt="5" fontWeight="bold" fontSize="xl">
        Getting Data....
      </Text>
      <Text>Hold on a moment while we get the requested data</Text>
    </Box>
  )
}

export default Loader
