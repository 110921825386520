import React from "react"

import { ApolloClient, InMemoryCache } from "apollo-boost"
import Cookies from "js-cookie"

import { onError } from "apollo-link-error"
import { setContext } from "apollo-link-context"
import { ApolloLink } from "apollo-link"
import { createUploadLink } from "apollo-upload-client"

import { Box } from "@chakra-ui/core"
import { toast } from "react-toastify"

const cache = new InMemoryCache()

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) =>
      toast.error(({ closeToast }) => <Box px="3">{message}</Box>)
    )
  }

  if (networkError) {
    toast.error(({ closeToast }) => <Box px="3">Oops, Network error</Box>)
  }
})

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get("tok-auth")
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const uploadLink = createUploadLink({
  uri: "https://identito-api-v2.herokuapp.com/graphql",
})
const link = ApolloLink.from([errorLink, authLink, uploadLink])

const client = new ApolloClient({
  cache,
  link,
})

cache.writeData({
  data: {
    isLoggedIn: !!Cookies.get("tok-auth"),
  },
})

export default client

// ENDPOITNS
// "http://localhost:8080/graphql"
// "https://identito-api.herokuapp.com/graphql"
// "https://api.identito.co/graphql"

//New test api
// https://identito-api-v2.herokuapp.com/graphql
