import React, { useState, useMemo, createContext } from "react"

export const ClientContext = createContext()

export default ({ children }) => {
  const [allClients, setAllClients] = useState("")
  const providerValue = useMemo(() => ({ allClients, setAllClients }), [
    allClients,
    setAllClients,
  ])
  return (
    <ClientContext.Provider value={providerValue}>
      {children}
    </ClientContext.Provider>
  )
}
