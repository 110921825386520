import React from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BrowserRouter, Switch, Route } from "react-router-dom"

//Local imports
import "./App.css"
// import Signup from "./pages/Auth/Signup"
import Login from "./pages/Auth/Login"
import Register from "./pages/Auth/Register"
// import Verify from "./pages/Auth/Verify"
import Layout from "./pages/Layout"

import ErrorBoundary from "./fallBack/ErrorBoundary.js"
import Page404 from "./fallBack/404.js"
import ForgotPassword from "./pages/Auth/ForgotPassword"
import ResetPassword from "./pages/Auth/ResetPassword"
import TestFeature from "./TestFeature"

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/test-feature" component={TestFeature} />
          <Route path="/register" component={Register} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/create-password" component={ForgotPassword} />
          <Route exact path="/404" component={Page404} />

          <Route path="/" component={Layout} />
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export default App
