import { theme } from "@chakra-ui/core"

export const CustomTheme = {
  ...theme,
  fonts: {
    heading: '"Nunito Sans", sans-serif',
    body: "Nunito Sans, sans-serif",
    mono: "Menlo, monospace",
  },
  colors: {
    ...theme.colors,
    brand: {
      primary: "#002F6C",
      secondary: "#BA0C2F",
      accent: "#FAF8D2",
      danger: "#FF647C",
      light: "#CEE6E5",
      alt: "#2276F0",
      linkColor: "#587878",
      iconGray: "#A7B1B1",
      gray: "#757575",
      gray2: "#999999",
      dark: "#151522",
    },

    fontSizes: {
      ...theme.fontSizes,
      brand: {
        mdNudge: "197px",
      },
    },
  },
}
