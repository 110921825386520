import React from "react"
import { NavLink, useHistory, Link } from "react-router-dom"
import {
  Box,
  Text,
  Flex,
  Divider,
  Switch,
  // useColorMode,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Image,
} from "@chakra-ui/core"
import Cookies from "js-cookie"

//assets
import anayticsIcon from "../../assets/Sidebar/analyticsIcon.svg"
import usersIcon from "../../assets/Sidebar/usersIcon.svg"
import clientsIcon from "../../assets/Sidebar/clientsIcon.svg"
import BatchIcon from "../../assets/Sidebar/BatchIcon.svg"
import downChevron from "../../assets/Sidebar/downChevron.svg"

const SidebarContent = () => {
  // const { colorMode, toggleColorMode } = useColorMode();

  let userData = JSON.parse(localStorage.getItem("userData"))

  let history = useHistory()
  //logout function
  const handleLogout = () => {
    Cookies.remove("tok-auth")
    localStorage.clear()

    history.replace("/")
  }

  return (
    <>
      {/* <Flex flexDirection="column"> */}
      <Flex flexDirection="column" py="23%" px={["4", "4", "4", "4"]}>
        <NavLink
          to="/"
          activeClassName="activeLink"
          className="nav"
          isActive={(match, location) => {
            const url = location.pathname
            if (match.isExact || url.includes("analytics")) {
              return true
            }
          }}
        >
          <Flex color="#fffbee" fontWeight="medium">
            <Image src={anayticsIcon} />
            <Text ml="3" my="auto">
              Analytics
            </Text>
          </Flex>
        </NavLink>

        <NavLink
          to="/admins"
          activeClassName="activeLink"
          className="nav"
          exact
        >
          <Flex color="#fffbee" fontWeight="medium">
            <Image src={usersIcon} />

            <Text ml="3" my="auto">
              Admins
            </Text>
          </Flex>
        </NavLink>

        <NavLink
          to="/clients"
          exact
          activeClassName="activeLink"
          className="nav"
        >
          <Flex color="#fffbee" fontWeight="medium">
            <Image src={clientsIcon} />
            <Text ml="3" my="auto">
              Clients
            </Text>
          </Flex>
        </NavLink>

        {/* Agents */}
        <NavLink
          to="/agents"
          exact
          activeClassName="activeLink"
          className="nav"
        >
          <Flex color="#fffbee" fontWeight="medium">
            <Image src={clientsIcon} />
            <Text ml="3" my="auto">
              Agents
            </Text>
          </Flex>
        </NavLink>

        <NavLink to="/batches" activeClassName="activeLink" className="nav">
          <Flex fontWeight="medium" color="#fffbee">
            <Image src={BatchIcon} />
            <Text ml="3" color="#fffbee" my="auto">
              Batches
            </Text>
          </Flex>
        </NavLink>
      </Flex>
      {/* <Box py="12"></Box> */}

      <Box mt="auto">
        <Divider borderColor="#808080" />
        <Menu>
          <MenuButton>
            <Box>
              <Flex px={["6", "6", "6", "3"]} pt="2" pb="3">
                <Avatar
                  color="white"
                  backgroundColor="yellow.500"
                  size="sm"
                  fontWeight="bold"
                  borderRadius="8px"
                  name={userData.full_name}
                />

                <Flex flexDirection="column" ml="4" my="auto" textAlign="left">
                  <Box
                    color="#fffbee"
                    fontSize="xs"
                    fontWeight="700"
                    isTruncated
                    width="130px"
                  >
                    {userData.full_name}
                  </Box>
                  <Box
                    color="#fff4d1"
                    fontSize="xs"
                    fontWeight="medium"
                    isTruncated
                    width="130px"
                  >
                    {userData.email}
                  </Box>
                </Flex>
                <Box my="auto" ml={["12", "12", "12", "4"]} ml="10">
                  <Icon name="arrow-up-down" color="white" />
                </Box>
              </Flex>
            </Box>
          </MenuButton>
          <MenuList>
            <Link to="/update-profile">
              <MenuItem>Update Profile</MenuItem>
            </Link>
            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </>
  )
}
export default SidebarContent
